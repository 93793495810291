import * as allProducts from './allProducts'
import { FormattedPrices } from 'prices/types'
import { Product, ProductCore, ProductVariant, ShopPages, VariantCore, VariantKey } from './types'
import { CartLineItem, LineItem } from 'cart/types'
import { MembershipProducts, PillowProducts } from './allProducts'
import { STRIPE_PRODUCTS } from '../stripe_lib/products'
import Stripe from 'stripe'

// export const allPodBeds = [...Object.values(allProducts.PodProProducts), ...Object.values(allProducts.PodCoverMattressProducts), ...Object.values(allProducts.PodProMaxProducts)]
export const allPodBeds = [...Object.values(allProducts.MattressProducts)]

export const allBase = [allProducts.TheBaseProducts.TheBaseNonMember, allProducts.TheBaseProducts.TheBase]

export const allPodCovers = [...Object.values(allProducts.PodCoverProducts)]

export const allPod4Covers = [allProducts.PodCoverProducts.Pod4]

export const allPodUltraCovers = [allProducts.PodCoverProducts.Pod4Ultra]

export const allMemberships = [...Object.values(allProducts.MembershipProducts)]

export const allBundleProducts = [...Object.values(allProducts.BundleProducts)]

export const sleepEsentialBundleProducts = [allProducts.BundleProducts.SleepEssentialBundle]

export const allProductsList = Object.values(allProducts).flatMap((family) => Object.values(family))

export const podCoverHandles = allPodCovers.map((p) => p.handle)

export const podCoverProductIds = allPodCovers.map((p) => p.id)
export const podBedProductIds = allPodBeds.map((p) => p.id)
export const membershipProductIds = allMemberships.map((p) => p.id)
export const bundleProductIds = allMemberships.map((p) => p.id)

export const podCoverVariantIds = allPodCovers.flatMap((item) => Object.values(item.variants).map((variant) => variant.id))
export const podBedVariantIds = allPodBeds.flatMap((item) => Object.values(item.variants).map((variant) => variant.id))
export const membershipVariantIds = allMemberships.flatMap((item) => Object.values(item.variants).map((variant) => variant.id))
export const baseUpgradeVariantIds = allBase.flatMap((item) => Object.values(item.variants).map((variant) => variant.id))
export const isPodCoverProductId = (id: string | number) => {
	return podCoverProductIds.includes(id as string)
}

export const isPodCoverHandle = (handle: string) => {
	return podCoverHandles.includes(handle)
}

export const isMemberUpgradeProductId = (id: string | number) => {
	return false
}

export const isPodBedProductId = (id: string | number) => {
	return podBedProductIds.includes(id as string)
}

export const isMembershipProductId = (id: string | number) => {
	return membershipProductIds.includes(id as string)
}

export const isMembershipVariantId = (id: string | number) => {
	return membershipVariantIds.includes(id as string)
}

export const isMemberUpgradeVariantId = (id: string | number) => {
	return false
}

export const isBundleProductId = (id: string | number) => {
	return bundleProductIds.includes(id as string)
}

export const isPodCoverVariantId = (id: string | number) => {
	return podCoverVariantIds.includes(id as string)
}

export const isPodBedVariantId = (id: string | number) => {
	return podBedVariantIds.includes(id as string)
}

export const isSleepElixirSubscriptionVariantId = (id: string | number) => {
	return allProducts.SupplementProducts.SleepElixir.variants.monthly.id === id
}

export const isSleepElixirSpecialOneTimeVariantId = (id: string | number) => {
	return allProducts.SupplementProducts.SleepElixir.variants.onetime.id === id
}

export const isPodOrCoverVariantId = (id: string | number) => {
	return isPodCoverVariantId(id) || isPodBedVariantId(id)
}

export const getMatchingCarbonAirPillowForVariants = (variant_ids: number[]) => {
	const firstPodOrCoverID = variant_ids.find((id) => isPodCoverVariantId(id) || isPodBedVariantId(id))
	if (firstPodOrCoverID) {
		const fullProduct = getProductByVariantId(firstPodOrCoverID)
		if (fullProduct) {
			const variant = getProductVariantByVariantId(fullProduct, firstPodOrCoverID)
			return variant && (variant.name === 'King' || variant.name === 'Cali King') ? PillowProducts.CarbonAirPillowKing : PillowProducts.CarbonAirPillow
		}
	}
	return PillowProducts.CarbonAirPillow
}

export const getMatchingUltraVariantForPod4 = (variant: VariantCore): string => {
	const ultraProductVariant = allProducts.PodCoverProducts.Pod4Ultra.variants[variant.key]
	return ultraProductVariant?.id ?? variant.id
}

export const getMatchingCarbonAirPillow = (variant: string | undefined) => {
	return variant === 'King' || variant === 'Cali King' ? PillowProducts.CarbonAirPillowKing : PillowProducts.CarbonAirPillow
}

export const getMatchingEightCare = (productId: number | undefined): 'podcover' | 'podmattress' => {
	if (!productId) {
		return 'podmattress'
	}
	if (isPodBedProductId(productId)) {
		return 'podmattress'
	}
	return 'podcover'
}

export const Accessories10PercentOff: ProductCore[] = []
export const Accessories25PercentOff: ProductCore[] = []
export const Accessories30PercentOff: ProductCore[] = []

export const Accessories20PercentOff: ProductCore[] = [
	allProducts.HypericeProducts.HypervoltPlus,
	allProducts.HypericeProducts.VenomBack,
	allProducts.NormaTecProducts.LegPulse2,
	allProducts.SprayProducts.LavenderSpray,
	allProducts.BlanketProducts.BrandedGravity,
	allProducts.FoundationProducts.SimpleFoundation,
	allProducts.PillowProducts.CarbonAirPillow,
	allProducts.PillowProducts.CarbonAirPillowKing,
	allProducts.ComforterProducts.AirLiteComforter,
	allProducts.ProtectorProducts.PodProtector,
	allProducts.SheetProducts.SheetSet,
	allProducts.SheetProducts.SheetSetGray,
]

export const MemberAccessoriesExtraPercentOff = [
	allProducts.HypericeProducts.HypervoltPlus,
	allProducts.HypericeProducts.VenomBack,
	allProducts.NormaTecProducts.LegPulse2,
	allProducts.SprayProducts.LavenderSpray,
	allProducts.BlanketProducts.BrandedGravity,
	allProducts.FoundationProducts.SimpleFoundation,
	allProducts.PillowProducts.CarbonAirPillow,
	allProducts.PillowProducts.CarbonAirPillowKing,
	allProducts.ComforterProducts.AirLiteComforter,
	allProducts.ProtectorProducts.PodProtector,
	allProducts.SheetProducts.SheetSet,
	allProducts.SheetProducts.SheetSetGray,
	allProducts.SupplementProducts.SleepElixir,
]

export const Collection10PercentOff = Accessories10PercentOff.map((a) => a.id)
export const Collection10PercentOffVariants = ([] as (number | string)[]).concat(...Accessories10PercentOff.map((a) => Object.values(a.variants).map((v) => v.id)))
export const Collection20PercentOff = Accessories20PercentOff.map((a) => a.id)
export const Collection20PercentOffVariants = ([] as (number | string)[]).concat(...Accessories20PercentOff.map((a) => Object.values(a.variants).map((v) => v.id)))
export const Collection25PercentOff = Accessories25PercentOff.map((a) => a.id)
export const Collection25PercentOffVariants = ([] as (number | string)[]).concat(...Accessories25PercentOff.map((a) => Object.values(a.variants).map((v) => v.id)))
export const Collection30PercentOff = Accessories30PercentOff.map((a) => a.id)
export const Collection30PercentOffVariants = ([] as (number | string)[]).concat(...Accessories30PercentOff.map((a) => Object.values(a.variants).map((v) => v.id)))

export const getProductByVariantId = (variantId: number | string) => {
	return allProductsList.find((it) => Object.keys(it.variants).find((k) => it.variants[k].id === variantId) != null)
}

export const getProductVariantByVariantId = (product: ProductCore, variantId: number | string): VariantCore | null => {
	const key = Object.keys(product.variants).find((k) => product.variants[k].id === variantId)
	return key ? product.variants[key] : null
}

export function createCartLineItemFromLineItem(lineItem: LineItem, prices?: FormattedPrices): CartLineItem | null {
	const product = getProductByVariantId(lineItem.variantId)
	if (!product) return null

	const variant = getProductVariantByVariantId(product, lineItem.variantId)
	if (!variant) return null

	if (!variant.id) return null

	return {
		quantity: lineItem.quantity,
		id: variant.id.toString(),
		variantId: variant.id,
		productId: product.id,
		productTitle: product.name,
		handle: product.handle,
		variantTitle: variant.name,
		price: prices ? prices.price : 0,
		comparePrice: prices && prices.comparePrice ? prices.comparePrice : 0,
		image: '',
		sku: '',
		attributes: lineItem.attributes,
		productDescription: '',
	}
}

export function createCartLineItem(item: ProductVariant, product: Product | ProductCore, custom?: any): CartLineItem {
	const obj = {
		...item,
		quantity: 1,
		id: item.id.toString(),
		variantId: item.id,
		productId: product.id,
		productTitle: product.name,
		handle: product.handle,
		variantTitle: item.name,
		price: item.prices.price,
		comparePrice: item.prices.comparePrice || 0,
		image: '',
		sku: '',
		attributes: [],
		productDescription: '',
		...custom,
	}
	return obj
}

export const getStripeAccessoryIds = () => {
	const families = [
		allProducts.BlanketProducts,
		allProducts.ComforterProducts,
		allProducts.FoundationProducts,
		allProducts.HypericeProducts,
		allProducts.NormaTecProducts,
		allProducts.PillowProducts,
		allProducts.ProtectorProducts,
		allProducts.SheetProducts,
		allProducts.SprayProducts,
		allProducts.SupplementProducts,
	]

	const ids: string[] = []

	for (const fam of families) {
		for (const productName in fam) {
			const product = fam[productName]
			for (const variantName in product.variants) {
				const variant = product.variants[variantName]
				if (variant.id) {
					ids.push(variant.id)
				}
			}
		}
	}

	return ids
}

export const getStripePodCoverIds = () => {
	const families = [allProducts.PodCoverProducts]

	const ids: string[] = []

	for (const fam of families) {
		for (const productName in fam) {
			const product = fam[productName]
			for (const variantName in product.variants) {
				const variant = product.variants[variantName]
				if (variant.id) {
					ids.push(variant.id)
				}
			}
		}
	}

	return ids
}

export const getStripeMattressIds = () => {
	const families = [allProducts.MattressProducts]

	const ids: string[] = []

	for (const fam of families) {
		for (const productName in fam) {
			const product = fam[productName]
			for (const variantName in product.variants) {
				const variant = product.variants[variantName]
				if (variant.id) {
					ids.push(variant.id)
				}
			}
		}
	}

	return ids
}

export function mapUrlToShop(url: string): ShopPages {
	switch (url) {
		case '/product/the-base':
		case '/product/the-base/':
			return 'the-base'
		case '/product/pod4-insert':
		case '/product/pod4-insert/':
			return 'pod4-insert'
		case '/product/pod-cover':
		case '/product/pod-cover/':
		case '/product/pod-pro-cover':
		case '/product/pod-pro-cover/':
		case '/product/shop-pod-cover':
		case '/product/shop-pod-cover/':
		case '/product/pod-cover-email':
		case '/product/pod-cover-email/':
			return 'cover'
		case '/product/pod4':
		case '/product/pod4/':
			return 'pod4'
		case '/product/pod4ultra':
		case '/product/pod4ultra/':
			return 'pod4ultra'
		case '/product/the-air-pillow':
		case '/product/the-air-pillow/':
			return 'pillow'
		case '/product/foundation':
		case '/product/foundation/':
			return 'foundation'
		case '/product/the-pod-sheet-set':
		case '/product/the-pod-sheet-set/':
			return 'sheets'
		case '/product/air-lite-comforter':
		case '/product/air-lite-comforter/':
			return 'comforter'
		case '/product/waterproof-protector':
		case '/product/waterproof-protector/':
			return 'protector'
		case '/product/the-sleep-essentials-bundle':
		case '/product/the-sleep-essentials-bundle/':
			return 'essentials-bundle'

		case '/product/gravity-blanket':
		case '/product/gravity-blanket/':
			return 'gravity-blanket'
		case '/product/lavender-sleep-spray':
		case '/product/lavender-sleep-spray/':
			return 'spray'
		case '/product/hypervolt-plus':
		case '/product/hypervolt-plus/':
			return 'hypervolt'
		case '/product/hypervolt-2-pro':
		case '/product/hypervolt-2-pro/':
			return 'hypervolt'
		case '/product/venom-back':
		case '/product/venom-back/':
			return 'venom-back'
		case '/product/leg-pulse':
		case '/product/leg-pulse/':
			return 'leg-pulse'
		case '/product/performance-report':
		case '/product/performance-report/':
			return 'performance-report'
		case '/product/eba234937b8d54e2d519b318707ea9713e6087b402b40670319c4c1e166e9208':
		case '/product/eba234937b8d54e2d519b318707ea9713e6087b402b40670319c4c1e166e9208/':
			return 'pod-pro-discounted-for-member-upgrade'
		case '/product/e7a45fxxnq9gnr4v9':
		case '/product/e7a45fxxnq9gnr4v9/':
			return 'pod-pro-discounted-for-member-upgrade'
		case '/product/dce234937b8d54e2r417b318707ea9713e6087b402b40670319c4c1e166e9101':
		case '/product/dce234937b8d54e2r417b318707ea9713e6087b402b40670319c4c1e166e9101/':
			return 'member-anniversary-upgrade'
		case '/product/sleep-fit-holiday-bundle':
		case '/product/sleep-fit-holiday-bundle/':
			return 'sleep-fit-holiday-bundle'
		case '/product/canopy-humidifier':
		case '/product/canopy-humidifier/':
			return 'canopy-humidifier'
		case '/product/premium-mattress/':
		case '/product/premium-mattress':
			return 'premium-mattress'
		case '/product/duvet-cover':
		case '/product/duvet-cover/':
			return 'duvet-cover'
		case '/product/sleep-elixir':
		case '/product/sleep-elixir/':
			return 'sleep-elixir'
		default:
			return 'cover'
	}
}

export const getStripePodCoverUpgradeIds = () => {
	const product = allProducts.PodCoverProducts.Pod3CoverPerfect
	const ids: string[] = []
	for (const variantName in product.variants) {
		const variant = product.variants[variantName]
		if (variant.id) {
			ids.push(variant.id)
		}
	}
	return ids
}

export const OFFERED_MEMBERSHIPS = () => {
	return [MembershipProducts.AutopilotStandard, MembershipProducts.AutopilotEnhanced]
}

export const stripeAccessoryIds = getStripeAccessoryIds()
export const stripePodCoverIds = getStripePodCoverIds()
export const stripeMattressIds = getStripeMattressIds()
export const stripePodCoverUpgradeIds = getStripePodCoverUpgradeIds()

export const isSleepElixirVariantId = (id: string | number): boolean => {
	const elixirIds = [STRIPE_PRODUCTS.sleep_elixir__monthly, STRIPE_PRODUCTS.sleep_elixir__one_month]
	return elixirIds.includes(id as string)
}

export const orderContainsSleepElixir = (invoice: Stripe.Invoice): boolean => {
	return invoice.lines.data.some((line) => isSleepElixirVariantId(line.price.id))
}

export const getVariantDimensions = (variant: VariantKey) => {
	switch (variant) {
		case 'full':
			return '53"W x 75"L'
		case 'cafull':
			return '135cm x 89cm'
		case 'queen':
			return '60"W x 80"L'
		case 'caqueen':
			return '150cm x 200cm'
		case 'king':
			return '76"W x 80"L'
		case 'caking':
		case 'mxking':
			return '190cm x 200cm'
		case 'caliking':
			return '72"W x 84"L'
		case 'ukking':
		case 'mxqueen':
			return '150cm x 200cm'
		case 'ukdouble':
		case 'eudouble':
		case 'sedouble':
		case 'dkdouble':
		case 'chdouble':
		case 'mxmatrimonial':
			return '135cm x 190cm'
		case 'eulargedouble':
		case 'seking':
		case 'dkking':
		case 'aequeen':
		case 'chlargedouble':
			return '160cm x 200cm'
		case 'uksuperking':
		case 'eusuperking':
		case 'sesuperking':
		case 'dksuperking':
		case 'auking':
		case 'aeking':
		case 'chsuperking':
			return '180cm x 200cm'
		case 'ukemperor':
		case 'euemperor':
		case 'seemperor':
		case 'dkemperor':
		case 'aeemperor':
		case 'chemperor':
			return '200cm x 200cm'
		case 'audouble':
			return '135cm x 190cm'
		case 'auqueen':
			return '150cm x 200cm'
		default:
			return ''
	}
}

export const getPodVariantOfLineItem = (lineItem: Stripe.InvoiceLineItem): VariantKey | null => {
	for (const product of Object.values(allProducts.PodCoverProducts)) {
		for (const variantKey of Object.keys(product.variants)) {
			const variant = product.variants[variantKey]
			if (variant.id === lineItem.price.id) {
				return variantKey as VariantKey
			}
		}
	}
	return null
}

export const getPodVariant = (invoice: Stripe.UpcomingInvoice | Stripe.Invoice): VariantKey | null => {
	const podLineItem = invoice.lines.data.map((lineItem) => getPodVariantOfLineItem(lineItem)).find(Boolean)
	return podLineItem ?? null
}
