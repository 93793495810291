import { allPodBeds, MemberAccessoriesExtraPercentOff, Accessories20PercentOff, sleepEsentialBundleProducts, allPodUltraCovers, allBase, allPod4Covers } from 'products/utils'
import { ProductCore } from 'products/types'
import { Currency, CurrencyDiscount, DiscountedVariants } from 'prices/types'
import { VANITY_DISCOUNT_CODES, vanityConfig } from 'config/vanity'
import { getActiveSitePromo } from 'config/site_promos'
import {
	EMAIL_SMS_DISCOUNT_AMOUNT,
	EMAIL_SMS_DISCOUNT_CODE,
	GLOBAL_AUD_BONUS,
	GLOBAL_CHF_BONUS,
	GLOBAL_SALE_DISCOUNT_AMOUNT,
	GLOBAL_SALE_DISCOUNT_MIRRORS,
	GLOBAL_SALE_MATTRESS_DISCOUNT_AMOUNT,
	GLOBAL_SALE_ULTRA_DISCOUNT_AMOUNT,
	MEMBERS_DISCOUNT_CODE,
	MEMBER_MATTRESS_DISCOUNT,
	MEMBER_UPGRADE_DISCOUNT,
	PARTNER_AUD_BONUS,
	PARTNER_COVER_DISCOUNT,
	PARTNER_MATTRESS_DISCOUNT,
	PARTNER_ULTRA_COVER_DISCOUNT,
	REFERRAL_COUPON,
	REFERRAL_DISCOUNT_MAP,
	SLEEP_ESSENTIALS_BUNDLE_DISCOUNT,
	ULTRA_MEMBER_UPGRADE_DISCOUNT,
	MEMBER_THEBASE_DISCOUNT_MAP,
	GLOBAL_MXN_BONUS,
} from 'stripe_lib/discounts'
import { VARIABLE_CURRENCY_MAP } from 'stripe_lib/scripts'

interface DiscountMap {
	[code: string]: DiscountedVariants
}

type CurrencyExchange = {
	[key in Currency]: number
}

function createDiscountMap(products: ProductCore[], discounts: CurrencyDiscount) {
	function reducer(aggregate: DiscountedVariants, product: ProductCore): DiscountedVariants {
		Object.values(product.variants).forEach((variant) => {
			aggregate[variant.id] = discounts
		})
		return aggregate
	}
	return products.reduce(reducer, {})
}

export const getVanityCodes = () => {
	return Object.values(vanityConfig).map((it) => it.promoCode?.toUpperCase())
}

const sliceDiscountMap = (discountMap: string) => {
	const amounts = discountMap.split('pod').join('').split('cover')
	const podAmount = amounts.length > 1 ? Number(amounts[0]) : 0
	const coverAmount = amounts.length > 1 ? Number(amounts[1]) : Number(amounts[0])
	const mattressAmount = podAmount >= coverAmount ? podAmount - coverAmount : 0
	return [coverAmount, coverAmount, mattressAmount] as [number, number, number]
}

const sliceNewDiscountMap = (discountMap: string) => {
	const amounts = discountMap.split('_')
	const mattressAmount = Number(amounts[0].replace('mattress', ''))
	const coverAmount = Number(amounts[1].replace('cover', ''))
	const coverUltraAmount = Number(amounts[2].replace('ultra', ''))
	return [coverUltraAmount, coverAmount, mattressAmount] as [number, number, number]
}

const generatePriceMap = (
	coverUltraDiscountPrice: number | string,
	coverDiscountPrice: number | string,
	mattressDiscountPrice: number | string,
	includeBundlePriceMap = true,
	australiaCoverBonus?: number,
	australiaUltraBonus?: number
) => ({
	...createDiscountMap(allPodBeds, {
		USD: mattressDiscountPrice,
		CAD: mattressDiscountPrice,
		GBP: 0,
		EUR: 0,
		AUD: 0,
		SEK: 0,
		DKK: 0,
		AED: 0,
		CHF: 0,
		MXN: 0,
	}),
	...createDiscountMap(allPod4Covers, {
		USD: coverDiscountPrice,
		CAD: coverDiscountPrice,
		GBP: coverDiscountPrice,
		EUR: coverDiscountPrice,
		CHF: coverDiscountPrice,
		AUD: Number(coverDiscountPrice) + (australiaCoverBonus ?? GLOBAL_AUD_BONUS),
		SEK: VARIABLE_CURRENCY_MAP.SEK[coverDiscountPrice],
		DKK: VARIABLE_CURRENCY_MAP.DKK[coverDiscountPrice],
		AED: VARIABLE_CURRENCY_MAP.AED[coverDiscountPrice],
		MXN: VARIABLE_CURRENCY_MAP.MXN[coverDiscountPrice],
	}),
	...createDiscountMap(allPodUltraCovers, {
		USD: coverUltraDiscountPrice,
		CAD: coverUltraDiscountPrice,
		GBP: coverUltraDiscountPrice,
		EUR: coverUltraDiscountPrice,
		CHF: coverUltraDiscountPrice,
		AUD: Number(coverUltraDiscountPrice) + (australiaUltraBonus ?? GLOBAL_AUD_BONUS),
		SEK: VARIABLE_CURRENCY_MAP.SEK[coverUltraDiscountPrice],
		DKK: VARIABLE_CURRENCY_MAP.DKK[coverUltraDiscountPrice],
		AED: VARIABLE_CURRENCY_MAP.AED[coverUltraDiscountPrice],
		MXN: VARIABLE_CURRENCY_MAP.MXN[coverUltraDiscountPrice],
	}),
	...createDiscountMap(allBase, MEMBER_THEBASE_DISCOUNT_MAP),
	...(includeBundlePriceMap
		? createDiscountMap(sleepEsentialBundleProducts, {
				USD: SLEEP_ESSENTIALS_BUNDLE_DISCOUNT,
				CAD: 0,
				GBP: 0,
				EUR: 0,
				CHF: 0,
				AUD: 0,
				SEK: 0,
				DKK: 0,
				AED: 0,
				MXN: 0,
		  })
		: {}),
})

const globalSalePriceMap = (coverUltraDiscountPrice: number, coverDiscountPrice: number, mattressDiscountPrice: number) => ({
	...createDiscountMap(allPodBeds, {
		USD: mattressDiscountPrice,
		CAD: mattressDiscountPrice,
		GBP: 0,
		EUR: 0,
		AUD: 0,
		SEK: 0,
		DKK: 0,
		AED: 0,
		CHF: 0,
		MXN: 0,
	}),
	...createDiscountMap(allPod4Covers, {
		USD: coverDiscountPrice,
		CAD: coverDiscountPrice,
		GBP: coverDiscountPrice,
		EUR: coverDiscountPrice,
		CHF: coverDiscountPrice + GLOBAL_CHF_BONUS,
		AUD: coverDiscountPrice + GLOBAL_AUD_BONUS, // most of the time Australia gets a bonus
		SEK: VARIABLE_CURRENCY_MAP.SEK[coverDiscountPrice],
		DKK: VARIABLE_CURRENCY_MAP.DKK[coverDiscountPrice],
		AED: VARIABLE_CURRENCY_MAP.AED[coverDiscountPrice],
		MXN: VARIABLE_CURRENCY_MAP.MXN[coverDiscountPrice + GLOBAL_MXN_BONUS],
	}),
	...createDiscountMap(allPodUltraCovers, {
		USD: coverUltraDiscountPrice,
		CAD: coverUltraDiscountPrice,
		GBP: coverUltraDiscountPrice,
		EUR: coverUltraDiscountPrice,
		CHF: coverUltraDiscountPrice + GLOBAL_CHF_BONUS,
		AUD: coverUltraDiscountPrice + GLOBAL_AUD_BONUS, // most of the time Australia gets a bonus
		SEK: VARIABLE_CURRENCY_MAP.SEK[coverUltraDiscountPrice],
		DKK: VARIABLE_CURRENCY_MAP.DKK[coverUltraDiscountPrice],
		AED: VARIABLE_CURRENCY_MAP.AED[coverUltraDiscountPrice],
		MXN: VARIABLE_CURRENCY_MAP.MXN[coverUltraDiscountPrice + GLOBAL_MXN_BONUS],
	}),
	...createDiscountMap(sleepEsentialBundleProducts, {
		USD: SLEEP_ESSENTIALS_BUNDLE_DISCOUNT,
		CAD: 0,
		GBP: 0,
		EUR: 0,
		CHF: 0,
		AUD: 0,
		SEK: 0,
		DKK: 0,
		AED: 0,
		MXN: 0,
	}),
	...createDiscountMap(allBase, MEMBER_THEBASE_DISCOUNT_MAP),
})

const addDiscountMaps = (sourceDiscountMap: DiscountedVariants, additionalDiscountMap: DiscountedVariants) => {
	const result = {}
	Object.keys(sourceDiscountMap).forEach((variantKey) => {
		const currencies: CurrencyExchange = sourceDiscountMap[variantKey]
		const resultCurrencies = {}
		Object.keys(currencies).forEach((currency) => {
			const value = currencies[currency] as number
			const addition = additionalDiscountMap[variantKey]?.[currency] as number
			if (value != null && addition != null) {
				resultCurrencies[currency] = value + addition
			} else {
				resultCurrencies[currency] = value ?? addition ?? 0
			}
		})
		result[variantKey] = resultCurrencies
	})
	return result
}

const VANITIES_DISCOUNTS = () =>
	VANITY_DISCOUNT_CODES.reduce((acc, code) => {
		acc[code] = generatePriceMap(PARTNER_ULTRA_COVER_DISCOUNT, PARTNER_COVER_DISCOUNT, PARTNER_MATTRESS_DISCOUNT, true, PARTNER_AUD_BONUS, PARTNER_AUD_BONUS)
		return acc
	}, {})

export const codes: DiscountMap = {
	hypericeperks: {
		...createDiscountMap(allPodBeds, {
			USD: 0,
			CAD: 0,
			GBP: 0,
			EUR: 0,
			CHF: 0,
			AUD: 0,
			SEK: 0,
			DKK: 0,
			AED: 0,
			MXN: 0,
		}),
		...createDiscountMap(allPod4Covers, {
			USD: '10%',
			GBP: '10%',
			EUR: '10%',
			CHF: '10%',
			CAD: '10%',
			AUD: '10%',
			SEK: '10%',
			DKK: '10%',
			AED: '10%',
			MXN: '10%',
		}),
		...createDiscountMap(allPodUltraCovers, {
			USD: '10%',
			GBP: '10%',
			EUR: '10%',
			CHF: '10%',
			CAD: '10%',
			AUD: '10%',
			SEK: '10%',
			DKK: '10%',
			AED: '10%',
			MXN: '10%',
		}),
		...createDiscountMap(sleepEsentialBundleProducts, {
			USD: SLEEP_ESSENTIALS_BUNDLE_DISCOUNT,
			CAD: 0,
			GBP: 0,
			EUR: 0,
			CHF: 0,
			AUD: 0,
			SEK: 0,
			DKK: 0,
			AED: 0,
			MXN: 0,
		}),
	},
	[MEMBERS_DISCOUNT_CODE.toLowerCase()]: {
		...createDiscountMap(allBase, MEMBER_THEBASE_DISCOUNT_MAP),
		...generatePriceMap(ULTRA_MEMBER_UPGRADE_DISCOUNT, MEMBER_UPGRADE_DISCOUNT, MEMBER_MATTRESS_DISCOUNT, true, 0, 0),
		...createDiscountMap(Accessories20PercentOff, {
			USD: '20%',
			GBP: '20%',
			EUR: '20%',
			CHF: '20%',
			CAD: '20%',
			AUD: '20%',
			SEK: '20%',
			DKK: '20%',
			AED: '20%',
			MXN: '20%',
		}),
		...createDiscountMap(MemberAccessoriesExtraPercentOff, {
			USD: '25%',
			GBP: '25%',
			EUR: '25%',
			CHF: '25%',
			CAD: '25%',
			AUD: '25%',
			SEK: '25%',
			DKK: '25%',
			AED: '25%',
			MXN: '25%',
		}),
	},

	paceline: generatePriceMap(200, 100, 100),
	RINGER: generatePriceMap(PARTNER_ULTRA_COVER_DISCOUNT, PARTNER_COVER_DISCOUNT, PARTNER_MATTRESS_DISCOUNT),
	AFF150: generatePriceMap(...sliceDiscountMap('pod150cover150')),
	travel: generatePriceMap(...sliceDiscountMap('pod150cover150')),
	CAM: generatePriceMap(PARTNER_ULTRA_COVER_DISCOUNT, PARTNER_COVER_DISCOUNT, PARTNER_MATTRESS_DISCOUNT),

	...VANITIES_DISCOUNTS(),
	EF200: generatePriceMap(PARTNER_ULTRA_COVER_DISCOUNT, PARTNER_COVER_DISCOUNT, PARTNER_MATTRESS_DISCOUNT, true, PARTNER_AUD_BONUS, PARTNER_AUD_BONUS),
	tim: generatePriceMap(PARTNER_ULTRA_COVER_DISCOUNT, PARTNER_COVER_DISCOUNT, PARTNER_MATTRESS_DISCOUNT, true, PARTNER_AUD_BONUS, PARTNER_AUD_BONUS),
	TQS: generatePriceMap(PARTNER_ULTRA_COVER_DISCOUNT, PARTNER_COVER_DISCOUNT, PARTNER_MATTRESS_DISCOUNT, true, PARTNER_AUD_BONUS, PARTNER_AUD_BONUS),
	dontdie: generatePriceMap(PARTNER_ULTRA_COVER_DISCOUNT + 50, PARTNER_COVER_DISCOUNT + 50, PARTNER_MATTRESS_DISCOUNT + 50, true, PARTNER_AUD_BONUS, PARTNER_AUD_BONUS),

	[REFERRAL_COUPON]: generatePriceMap(...sliceNewDiscountMap(REFERRAL_DISCOUNT_MAP), true, 0, 0),

	...GLOBAL_SALE_DISCOUNT_MIRRORS.map((code) => [code.toLowerCase(), globalSalePriceMap(GLOBAL_SALE_ULTRA_DISCOUNT_AMOUNT, GLOBAL_SALE_DISCOUNT_AMOUNT, GLOBAL_SALE_MATTRESS_DISCOUNT_AMOUNT)]).reduce(
		(acc, it) => {
			const code = it[0] as string
			acc[code] = it[1]
			return acc
		},
		{}
	),
}

export const activeSiteSale = getActiveSitePromo()

const hasActiveSitePromoCode = () => activeSiteSale && activeSiteSale.promoCode && !!codes[activeSiteSale.promoCode]
const getActiveSitePromoCode = () => (hasActiveSitePromoCode() ? codes[activeSiteSale.promoCode] : {})

// const VANITIES_EMAIL50_DISCOUNTS = () =>
// 	VANITY_DISCOUNT_CODES.reduce((acc, code) => {
// 		if (!codes[code]) return acc
// 		acc[`${code}50`] = addDiscountMaps(codes[code], generatePriceMap(50, 50, 0, false))
// 		// acc[`${code}50`] = addDiscountMaps(mergeDiscountMaps(codes[code], codes[activeSiteSale.promoCode]), generatePriceMap(50, 0)) // TODO merge discount with global sale to make sure the highest discount is applying
// 		return acc
// 	}, {})

export const discountCodes: DiscountMap = {
	...codes,
	// ...VANITIES_EMAIL50_DISCOUNTS(),

	// below are global sale stacking codes
	...(hasActiveSitePromoCode() && {
		[EMAIL_SMS_DISCOUNT_CODE.toLowerCase()]: addDiscountMaps(getActiveSitePromoCode(), generatePriceMap(EMAIL_SMS_DISCOUNT_AMOUNT, EMAIL_SMS_DISCOUNT_AMOUNT, 0, false, 0, 0)),
	}),
	...(hasActiveSitePromoCode() && { pod50: addDiscountMaps(getActiveSitePromoCode(), generatePriceMap(50, 50, 0, false, 0, 0)) }),
}
